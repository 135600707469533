// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  eth: {
    chainId: "0x1", //hexadecimal for metamask
    chainIdInt: 1,
    chainName: "Ethereum Mainnet",
    gasFees: 0.008,
    purchaseToken: "ETH",
    contact: "0xaf1cfb3d6759179bc5be21358a437e81de78a157",
    token: "0x2b867efD2dE4Ad2B583Ca0CB3dF9C4040Ef4D329",
    rpcURL: "https://intensive-dimensional-needle.quiknode.pro/9edfd12a0b36a9052d365de5275f463fef63d266/",
    backupRPC: "https://intensive-dimensional-needle.quiknode.pro/9edfd12a0b36a9052d365de5275f463fef63d266/",
    blockExplorerURL: "https://etherscan.io/tx/",
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: "0x1",
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
        rpcUrls: ["https://rpc.ankr.com/eth"],
        blockExplorerUrls: ["https://etherscan.io/"],
      }]
    }
  },
  bsc: {
    chainId: "0x38", //hexadecimal for metamask
    chainIdInt: 56,
    chainName: "BSC Mainnet",
    gasFees: 0.0025,
    purchaseToken: "BNB",
    contact: "0xaf1cfb3d6759179bc5be21358a437e81de78a157",
    token: "0x2cD96e8C3FF6b5E01169F6E3b61D28204E7810Bb",
    rpcURL: "https://morning-hardworking-panorama.bsc.quiknode.pro/7ce27cb8104ec71dd1c8bd52a0b3dcd7f3d46b6d/",
    backupRPC: "https://morning-hardworking-panorama.bsc.quiknode.pro/7ce27cb8104ec71dd1c8bd52a0b3dcd7f3d46b6d/",
    blockExplorerURL: "https://bscscan.com/tx/",
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: "0x38",
        chainName: "BSC Mainnet",
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed2.ninicoin.io/"],
        blockExplorerUrls: ["https://bscscan.com/"],
      }]
    }
  },
  walletConnectId: 'e4aadd29a96687155fe1b484e8997916',
  tokenDecimal: 7
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
