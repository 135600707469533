import { ApplicationRef, Injectable } from "@angular/core";
import { configureChains, connect, fetchBalance, prepareWriteContract, readContracts, switchNetwork, watchAccount, watchNetwork, writeContract, disconnect, waitForTransaction, mainnet, createConfig } from '@wagmi/core'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { publicProvider } from '@wagmi/core/providers/public'

import { bsc, goerli, bscTestnet } from "viem/chains";
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import { formatEther, parseEther } from "viem";


import { PopupService } from "./popup.service";
import { EventService } from "./event.service";
import { SwapStatus } from "../shared/constants/app-enums";
import { environment } from "src/environments/environment";


const bridgeBsc = require('src/assets/contracts/BridgeBSC.json');
const bridgeEth = require('src/assets/contracts/BridgeETH.json');
const token = require('src/assets/contracts/token.json');

const bridgeContractBsc = {
  address: environment.bsc.contact as any,
  abi: bridgeBsc.abi,
}
const bridgeContractEth = {
  address: environment.eth.contact as any,
  abi: bridgeEth.abi,
}

@Injectable({
  providedIn: 'root'
})

export class WalletConnectService {
  taxAmount = 0;
  claimableTokens = 0;
  tokenBalance = 0;
  swapStatus = SwapStatus.not_started;
  client: any;
  wagmiData: any;
  walletAddress: string = '';
  referUrl = '';
  chainId: any;
  mode: any;
  listnerAdded = false;
  chainData: {
    bridgeSlots: number,
    minBridgeAmt: number,
    ethTokenBal: number,
    bscTokenBal: number,
    activeBridgeSlot: number,
    activeVestSlot: number,
    vestingSlots: number,
    bridgeSlotData?: any,
    vestingSlotData?: any,
    claimableTokens: number
  } = {
      bridgeSlots: 0,
      minBridgeAmt: 0,
      ethTokenBal: 0,
      bscTokenBal: 0,
      activeBridgeSlot: -1,
      activeVestSlot: -1,
      vestingSlots: 0,
      claimableTokens: 0
    }
  metaMaskConnector: any;
  walletConnector: any;

  swapHash: any = '';
  refreshId: any;
  refreshRate: number = 10000;
  okWebSocket: boolean = false;

  constructor(
    private ref: ApplicationRef,
    private popupService: PopupService,
    private eventService: EventService
  ) { }


  async activateSite() {

    /**
     * Wagmi Core chain, provider and connector configuration.
     * If production mode then mainnet and paid RPC provider else
     * testnet and publicProvider
     */

    const currentChain: any = environment.production ? [mainnet, bsc] : [goerli, bscTestnet];
    const { chains, publicClient, webSocketPublicClient } = configureChains(
      currentChain,
      environment.production ?
        [
          jsonRpcProvider({
            rpc: (chain) => ({
              http: chain.id === environment.eth.chainIdInt ? environment.eth.rpcURL : environment.bsc.rpcURL,
            }),
          }),
          jsonRpcProvider({
            rpc: (chain) => ({
              http: chain.id === environment.eth.chainIdInt ? environment.eth.backupRPC : environment.bsc.backupRPC,
            }),
          })
        ]
        : [publicProvider()]
    )


    /**
     * Setup of Metamask connector which will work
     * with metamask browser extension
     */

    this.metaMaskConnector = new MetaMaskConnector({
      chains: chains,
    })



    /**
     * Setup of wallet connector v2 which will work
     * with mobile app like trustwallet, alphawallet app.
     * the projectId is mandatory and can be generated from
     * https://cloud.walletconnect.com/sign-in
     */

    this.walletConnector = new WalletConnectConnector({
      chains,
      options: {
        projectId: environment.walletConnectId
      },
    })


    /**
     * wagmi createConfig setup for auto connect feature
     * and available connectors of wagmi.
     */

    this.client = createConfig({
      autoConnect: true,
      connectors: [this.metaMaskConnector, this.walletConnector],
      publicClient,
      webSocketPublicClient,
    });



    /**
     * watchNetwork function continuously monitors the user’s
     * connected network. Whenever user switched/changed its
     * current network, this function keeps tracking that data
     * and updates the current chain id accordingly.
     */

    watchNetwork((network: any) => {
      this.chainId = network?.chain?.id;
      this.eventService.isBsc = this.chainId == environment.bsc.chainIdInt;
      this.getPresalesData();
      setTimeout(() => this.eventService.setNetwork(), 2000);
      this.ref.tick();
      console.log('watchNetwork =', network);
    });


    /**
     * wallet account. Whenever user switched/changed its current account,
     * This function continuously monitors the user’s connected
     * this function updates the current wallet address accordingly.
     */
    watchAccount((account: any) => this.setData(account));

  }

  isCorrectChain(): boolean {
    return this.eventService.isBsc ? (this.chainId === environment.bsc.chainIdInt) : (this.chainId === environment.eth.chainIdInt);
  }

  async connectWallet(mode: string) {
    this.mode = mode;
    await connect({
      connector: mode === 'metamask' ? this.metaMaskConnector
        : mode === 'wallet' ? this.walletConnector
          : this.walletConnector
    })
      .then((success: any) => {
        console.log('\n======= wallet connected ==========\n', this.client, '\n======================');
      },
        (err: any) => {
          console.log('\n======= wallet connection issue ==========\n', err);
        })
  }

  async disConnectWallet() {
    await disconnect()
      .then((success: any) => {
        // console.log('\n======= wallet disconnected ==========\n',this.client);
        // localStorage.clear();
        this.referUrl = '';
        this.chainId = null;
        this.walletAddress = '';
        this.chainData.bridgeSlots = 0;
        this.chainData.minBridgeAmt = 0;
        this.eventService.setNetwork();
        this.eventService.broadcastEvent('IS_WEB3', false);
      },
        (err: any) => {
          console.log('\n======= wallet disconnect issue ==========\n');
          console.log('disconnect issue =', err);
        })
  }

  setData(account: any): void {
    this.wagmiData = account;
    this.walletAddress = this.wagmiData.address;;
    this.ref.tick();
    this.getPresalesData();
    console.log('wagmiData', this.wagmiData);
  }

  async getPresalesData(getData: boolean = false) {
    const config = {
      contracts: [
        {
          ...bridgeContractBsc,
          functionName: 'totalBridgeSlots',
          chainId: environment.bsc.chainIdInt, //0
          args: []
        },
        {
          ...bridgeContractBsc,
          functionName: 'minimumBridgeAmount',
          chainId: environment.bsc.chainIdInt, //1
          args: []
        },
        {
          ...bridgeContractEth,
          functionName: 'totalVestingSlots',
          chainId: environment.eth.chainIdInt, //8
          args: []
        }
      ]
    }

    if (this.walletAddress) {
      let slotsData: any = await readContracts(config);
      if (this.chainId == environment.bsc.chainIdInt) {
        let tokenBal = await fetchBalance({
          address: this.walletAddress as any,
          token: environment.bsc.token as any,
          chainId: environment.bsc.chainIdInt
        });
        this.tokenBalance = this.chainData.bscTokenBal = +tokenBal.formatted;
      } else {
        let tokenBal = await fetchBalance({
          address: this.walletAddress as any,
          token: environment.eth.token as any,
          chainId: environment.eth.chainIdInt
        });
        this.tokenBalance = this.chainData.ethTokenBal = +tokenBal.formatted;
      }
      console.log('\n******\presaleData =', slotsData, '\n******\n');
      this.chainData.minBridgeAmt = Number(slotsData[1].result) / Math.pow(10, 9)
      this.chainData.bridgeSlots = Number(slotsData[0].result);
      this.chainData.vestingSlots = Number(slotsData[2].result);
      let config2: any = { contracts: [] }
      for (let i = 1; i <= this.chainData.bridgeSlots; i++) {
        config2.contracts.push({
          ...bridgeContractBsc,
          functionName: 'bridgeSlots',
          chainId: environment.bsc.chainIdInt,
          args: [i]
        });
        config2.contracts.push({
          ...bridgeContractBsc,
          functionName: 'bridgedTokens',
          chainId: environment.bsc.chainIdInt,
          args: [this.walletAddress, i]
        })
      }
      for (let i = 1; i <= this.chainData.vestingSlots; i++) { //TODO maybe fix this in mainnet 
        config2.contracts.push({
          ...bridgeContractEth,
          functionName: 'vestingSlots',
          chainId: environment.eth.chainIdInt,
          args: [i]
        });
        config2.contracts.push({
          ...bridgeContractEth,
          functionName: 'vestings',
          chainId: environment.eth.chainIdInt,
          args: [this.walletAddress, i]
        });
        config2.contracts.push({
          ...bridgeContractEth,
          functionName: 'getClaimableAmount',
          chainId: environment.eth.chainIdInt,
          args: [this.walletAddress, i]
        })
      }
      let slotsData2: any = await readContracts(config2);
      this.chainData.bridgeSlotData = [];
      this.chainData.vestingSlotData = [];
      for (let i = 0; i < this.chainData.bridgeSlots; i++) {
        let slotData = {
          id: i + 1,
          startTime: +Number(slotsData2[i * 2].result[0]) * 1000,
          endTime: +Number(slotsData2[i * 2].result[1]) * 1000,
          vestedAmount: Number(slotsData2[i * 2 + 1].result) / Math.pow(10, 9)
        }
        // if (slotData.vestedAmount > 0)
        this.chainData.bridgeSlotData.push(slotData);
        let currTime = new Date().getTime()
        if (slotData.startTime <= currTime && slotData.endTime >= currTime) {
          this.chainData.activeBridgeSlot = slotData.id;
        }
      }
      let claimsToken = 0;

      for (let i = 0; i < this.chainData.vestingSlots; i++) { //TODO maybe fix this in mainnet 
        let sumConst = (this.chainData.bridgeSlots * 2) + ((i) * 3);
        console.log(sumConst, this.chainData.bridgeSlots)
        let vestData = {
          id: i + 1, // since it starts from 1
          startTime: +Number(slotsData2[sumConst].result[0]) * 1000,
          vestingDuration: +Number(slotsData2[sumConst].result[1]),
          totalAmount: Number(slotsData2[sumConst + 1].result[0]) / Math.pow(10, 9),
          claimedAmount: Number(slotsData2[sumConst + 1].result[1]) / Math.pow(10, 9),
          claimableAmount: Number(slotsData2[sumConst + 2].result) / Math.pow(10, 9)
        }
        this.chainData.vestingSlotData.push(vestData);
        let currTime = new Date().getTime()
        if (vestData.startTime <= currTime && (vestData.startTime + vestData.vestingDuration) >= currTime) {
          this.chainData.activeVestSlot = i;
        }
        claimsToken += vestData.claimableAmount;
      }
      this.chainData.claimableTokens = claimsToken
      console.log(this.chainData, slotsData2, config2);
    }
    // let checkpointBSC = slotsData[6].status === "success" ? Number(slotsData[6].result) : 0;
    // let checkpointETH = slotsData[12].status === "success" ? Number(slotsData[12].result) : 0;
    // let bscRaised = +formatEther(slotsData[5].result || 0);
    // let ethRaised = +formatEther(slotsData[11].result || 0);

    // this.balanceData.bscTokenBal = checkpointBSC > 0 ? checkpointBSC : Number(slotsData[1].result);
    // this.balanceData.ethTokenBal = checkpointETH > 0 ? checkpointETH : Number(slotsData[8].result);

    if (this.refreshId) clearTimeout(this.refreshId);
    this.refreshId = setTimeout(() => this.getPresalesData(), this.refreshRate);
  }

  async switchNetwork(chainId: number) {
    await switchNetwork({
      chainId: chainId,
    }).then((success: any) => {
      this.ref.tick();
    },
      (err: any) => {
        const chain = this.eventService.isBsc ?
          chainId === environment.bsc.chainIdInt ? environment.bsc.chainInfo
            : environment.eth.chainInfo
          : environment.bsc.chainInfo;
        const chainName = chain.params[0].chainName;
        this.popupService.messagePopup("info", "Switching Chain Failed. Please try to switch to chain " + chainName + " manually.");
      });
  }

  //  ************* Swap Token to BSC **************

  async bridgeToEth(afterAllowance = false) {

    const tokenContract = {
      address: this.eventService.isBsc ? environment.bsc.token as any : environment.eth.token as any,
      abi: token.abi,
    }
    const presaleAdd = this.eventService.isBsc ? environment.bsc.contact as any : environment.eth.contact as any;

    const allowanceData = await readContracts({
      contracts: [
        {
          ...tokenContract,
          functionName: 'allowance',
          chainId: this.chainId,
          args: [this.walletAddress, presaleAdd],
        },
      ],
    })
    let allowanceValue;
    allowanceValue = Number(allowanceData[0].result) / Math.pow(10, 9);
    console.log('allowanceData =', allowanceValue, allowanceData);

    if (+this.chainData.bscTokenBal > +allowanceValue) {
      const { request } = await prepareWriteContract({
        ...tokenContract,
        chainId: this.chainId,
        functionName: 'approve',
        args: [presaleAdd, (afterAllowance || allowanceValue == 0) ? '100000000000000000000000000' : '0'],
        account: this.walletAddress as any    // Optional param. Not required!
      })

      console.log('approveConfig =', request)
      this.swapStatus = SwapStatus.approval_pending;
      const { hash } = await writeContract(request);
      console.log('hash =', hash)
      await waitForTransaction({ hash })
        .then((success: any) => {
          console.log('approve Config success', success);
          if (afterAllowance) {
            this.moveTokens();
          } else {
            this.bridgeToEth(true);
          }
        },
          (err: any) => {
            console.log('approve Config error', err);
            this.swapStatus = SwapStatus.rejected;
          });
    } else {
      this.moveTokens();
    }
  }


  //  ************* Buy USDT **************

  async moveTokens() {
    const contract = this.eventService.isBsc ? bridgeContractBsc : bridgeContractEth;
    const { request } = await prepareWriteContract({
      ...contract,
      functionName: 'bridgeTokens',
      chainId: this.chainId,
      args: [this.chainData.activeBridgeSlot],
      // account: this.walletAddress  // Optional param. Not required!
    });

    console.log('usdtConfig =', request)
    this.swapStatus = SwapStatus.not_started;
    await writeContract(request)
      .then((success: any) => {
        console.log(' writeContract success', success);
        this.swapStatus = SwapStatus.in_progess;
        this.checkTRansaction(success.hash, 'usdt', 0, 0);
      },
        (err: any) => {
          console.log(' writeContract error', err);
          this.swapStatus = SwapStatus.failed;
        });
  }

  async checkTRansaction(haskKey: string, mode: string, amount: number, value: number) {
    await waitForTransaction({ hash: haskKey as any })
      .then((response: any) => {
        console.log('checkTansaction success', response);
        this.popupService.messsageAlert("success", "Transaction Complete")
        this.swapStatus = SwapStatus.complete;
        this.swapHash = response.transactionHash;
      },
        (err: any) => {
          console.log('checkTansaction error', err);
          this.swapStatus = SwapStatus.failed;
        });
  }

  async claim(slotId: number) {
    console.log('Claim called');
    this.swapStatus = SwapStatus.not_started;
    const { request } = await prepareWriteContract({
      ...bridgeContractEth,
      functionName: 'claimTokens',
      chainId: environment.eth.chainIdInt,
      args: [slotId],
      // account: this.walletAddress  // Optional param. Not required!
    })
    await writeContract(request)
      .then((success: any) => {
        this.checkTRansaction(success.hash, 'usdt', 0, 0);
        this.swapStatus = SwapStatus.in_progess;
        console.log('Claim success', success);
      },
        (err: any) => {
          console.log('Claim error', err);
        });
  }
}
