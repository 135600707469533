<header class="container d-flex flex-column flex-sm-row">
  <a href="javascript:void(0)" (click)="disconnect()" class="headerLogo me-sm-3 mb-3 mb-sm-0">
    <img src="./assets/images/lucky-block.svg" alt="">
    <span>LUCKY BRIDGE</span>
  </a>


  <div class="connect-wallet d-flex align-items-center ms-auto" *ngIf="windowSize >= 768">
    <div class="d-flex align-items-center">

      <ng-container *ngIf="walletAddress">
        <span class="fs-5 fw-medium text-white"> <button matTooltip="Switch Network" (click)="onSwitch()"
            aria-label="switch network" class="switch_button d-flex align-items-center"><img
              src="../../../assets/images/up_down_arrow.svg" alt="" height="16" width="16"></button></span>

        <a href="javascript:void(0)" class="text-decoration-underline text-white fs-5 fw-lighter me-3 me-md-4"
          (click)="disconnect()">Disconnect</a>
        <div class="d-flex align-items-center">
          <span class="_dot bg-success me-2"></span>

          <span class="fs-5 fw-medium text-white">{{walletAddress.slice(0,9) + "..." +
            walletAddress.slice(walletAddress.length-4, walletAddress.length)}}</span>
          <img src="./assets/images/user_circle.svg" alt="" class="userIcon ms-3">
        </div>
      </ng-container>

      <button *ngIf="!walletAddress" class="btn btn-gradient" (click)="openWalletDialog()">CONNECT WALLET</button>



      <ul class="footer-list m-0 p-0">
        <li><a href="https://twitter.com/luckyblockcoin?s=21" target="_blank"> <img
              src="assets/images/svg-icons/twitter.svg" alt="" class="cursor-pointer"></a></li>
        <li><a href="https://t.me/OfficialLuckyBlock" target="_blank"><img src="assets/images/svg-icons/telegram.svg"
              alt="" class="cursor-pointer"></a></li>

        <li><a href="https://discord.gg/U9HDC2Tsmk" target="_blank"><img src="assets/images/svg-icons/discord.svg"
              alt="" class="cursor-pointer"></a></li>
        <li>
          <a href="https://youtube.com/@LuckyBlockCoin" target="_blank" class="cursor-pointer">
            <img src="../../../assets/images/svg-icons/youtube.svg" alt=""></a>
        </li>
        <li><a href="https://m.facebook.com/LuckyBlockLB" target="_blank" class="cursor-pointer"> <img
              src="../../../assets/images/svg-icons/facebook.svg" alt=""></a></li>
        <li><a href="https://instagram.com/luckyblockcasino?igshid=OGIzYTJhMTRmYQ==" target="_blank"
            class="cursor-pointer"> <img src="../../../assets/images/svg-icons/instagram.svg" alt=""></a></li>
      </ul>
    </div>

  </div>
</header>


<!-- Wallet connect modal popup -->
<ng-template #walletDialog>
  <div class="fs-3 fw-bold mb-4 text-uppercase text-center">SELECT A PROVIDER</div>
  <div class="d-flex justify-content-around gap-3 text-center">
    <div class="cursor-pointer walletBtn" (click)="connectWallet('metamask')" role="button" *ngIf="windowSize >= 768">
      <img src="./assets/images/metamask.svg" alt="metamask" class="walletIcon mb-3">
      <span class="d-block fs-5 fw-bold text-uppercase">Metamask</span>
    </div>
    <div class="cursor-pointer walletBtn" (click)="connectWallet('wallet')" role="button">
      <img src="./assets/images/wallet_connect.svg" alt="wallet_connect" class="walletIcon mb-3">
      <span class="d-block fs-5 fw-bold text-uppercase">Wallet Connect</span>
    </div>
  </div>
</ng-template>

<ng-template #networkDialog>
  <div class="wrong-network fs-1 mb-4 text-center">You are on wrong chain, please switch to BSE or ETH chain</div>
  <div class="fs-3 fw-bold mb-4 text-uppercase text-center">SELECT NETWORK</div>
  <div class="d-flex justify-content-around gap-3 text-center">
    <div class="cursor-pointer walletBtn" (click)="connectBSC()" role="button">
      <img src="./assets/images/bnb-icon.svg" alt="wallet_connect" class="walletIcon mb-3">
      <span class="d-block fs-5 fw-bold text-uppercase">BSC</span>
    </div>
    <div class="cursor-pointer walletBtn" (click)="connectETH()" role="button">
      <img src="./assets/images/ethereum.svg" alt="metamask" class="walletIcon mb-3">
      <span class="d-block fs-5 fw-bold text-uppercase">Ethereum</span>
    </div>
  </div>
</ng-template>