import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PopupService } from 'src/app/services/popup.service';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { SwapStatus } from 'src/app/shared/constants/app-enums';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {
  claimableBalance = 0;
  SwapStatus = SwapStatus;
  transactionData: any;
  private subscriptions: Subscription[] = [];
  constructor(
    public popupService: PopupService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClaimComponent>,
    private walletConnectService: WalletConnectService
  ) { 
    this.service.swapStatus = SwapStatus.not_started;
  }

  //to find if metamask is used to walletconnect
  get service() {
    return this.walletConnectService;
  }

  ngOnInit(): void {
    console.log(this.data)
    this.claimableBalance = this.data.claim;
    this.claimTokens();
  }

  get walletAddress() {
    return this.service.walletAddress;
  }

  get status() {
    return this.service.swapStatus;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  closePopup(message = 'Transaction Cancelled'): void {
    this.dialogRef.close(message);
  }

  //to check if its bsc to eth network
  isBSCChain() {
    return this.service.chainId == environment.bsc.chainIdInt;
  }

  //function for claiming tokens
  claimTokens = async () => {
    try {
      await this.service.claim(this.data.slotId);
      console.log("success");
    }
    catch (err: any) {
      this.popupService.messsageAlert("error", "Transaction Failed")
      console.log(err);
      if (err && err.code == 4001) {
        this.service.swapStatus = SwapStatus.rejected;
      } else {
        this.service.swapStatus = SwapStatus.failed;
      }
    }
  }

  //get transaction hash
  tsHash() {
    let url: any;
    const append = "tx/" + this.walletConnectService.swapHash;
    if (this.isBSCChain()) {
      url = environment.bsc.chainInfo.params[0].blockExplorerUrls + append;
    }
    else {
      url = environment.eth.chainInfo.params[0].blockExplorerUrls + append;
    }
    window.open(url, '_target')
  }
}
