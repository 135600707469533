import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  loader:boolean =false;
  constructor() { }

  // error toast message
  messsageAlert(icon: SweetAlertIcon, text: string) {
    let backgroundClass;
    if(icon === 'error') {
      backgroundClass = 'bg-danger';
    } else if (icon === 'success') {
      backgroundClass = 'bg-success';
    } else if (icon === 'info') {
      backgroundClass = 'bg-info';
    } else if (icon === 'warning') {
      backgroundClass = 'bg-warning';
    } else{
      backgroundClass = 'bg-gray-medium';
    }
    const options: SweetAlertOptions = {
      position: 'bottom-end',
      timer: 3000,
      toast: true,
      timerProgressBar: true,
      showConfirmButton: false,
      iconColor: '#fff',
      icon: icon,
      text: text,
      customClass: {
        popup: 'd-flex align-items-center rounded-sm ' + backgroundClass,
        htmlContainer: 'font-size-sm font-weight-light text-white',
      }
    };
    return Swal.fire(options);
  }

  // message popup
  messagePopup(icon: SweetAlertIcon, text: string, titleText?: string, confirmBtnTxt?: string, denyBtnTxt?: string, cancelBtnTxt?: string) {
    const options: SweetAlertOptions = {
      iconHtml: '<img src="./assets/images/'+icon+'.svg" alt="'+icon+'">',
      titleText: titleText ? titleText : undefined,
      text: text,
      showDenyButton: denyBtnTxt ? true : false,
      showCancelButton: cancelBtnTxt ? true : false,
      confirmButtonText: confirmBtnTxt ? confirmBtnTxt : 'Okay',
      denyButtonText: denyBtnTxt,
      cancelButtonText: cancelBtnTxt,
      buttonsStyling: false,
      customClass: {
        icon: 'border-0',
        popup: 'rounded bg-dark border border-gray-dark text-white pb-4 pb-lg-5',
        title: 'font-size-lg',
        htmlContainer: 'font-weight-light font-size-md',
        confirmButton: 'btn btn-gradient',
        denyButton: 'btn btn-gradient-border',
        cancelButton: 'btn btn-gradient-border'
      }
    };
    return Swal.fire(options);
  }

  close() {
    return Swal.close();
  }
}
