<div class="modalHeader mb-4 text-center">
  <div class="fs-1 fw-bold text-uppercase mb-2">BRIDGE YOUR LBLOCK</div>
  <div class=" text-white" *ngIf="status != SwapStatus.complete">Confirm the transaction in Wallet to swap your
    {{baseToken}} {{isBSCChain() ? 'BSC':
    'Ethereum'}}
    LBLOCK tokens to the {{!isBSCChain() ? 'BSC':'Ethereum'}} network.
  </div>
  <div class=" text-white" *ngIf="status == SwapStatus.complete">Thank you for bridging your LBLOCK tokens to the
    {{isBSCChain() ? 'BSC':
    'Ethereum'}} network.
  </div>
  <div class="modalContent p-3 p-md-4 rounded m-2">
    <div class="d-flex align-items-center justify-content-between mb-3 mb-md-4">
      <span class="fs-5 fw-bold me-2">Bridge {{getStatus()}}</span>
      <a (click)="closePopup()" class="closeBtn ms-auto" role="button">
        <img src="./assets/images/close.svg" alt="" style="height: 70%; width: 70%;">
      </a>
    </div>

    <div class="d-flex justify-content-center pt-4"
      [class.darken]="status == SwapStatus.failed || status == SwapStatus.rejected || status == SwapStatus.complete">
      <img src="./assets/images/bnb-icon.svg" alt="" height="75" width="43" class="mr-2" *ngIf="isBSCChain()">
      <img src="./assets/images/ethereum.svg" alt="" height="75" width="44" class="ml-2" *ngIf="!isBSCChain()">

      <img src="./assets/images/animation_640_l2qap6k4.gif" alt="" height="75" width="130" class="mx-3 mb-2">
      <img src="./assets/images/ethereum.svg" alt="" height="75" width="44" class="ml-2" *ngIf="isBSCChain()">
      <img src="./assets/images/bnb-icon.svg" alt="" height="75" width="43" class="mr-2" *ngIf="!isBSCChain()">


    </div>
    <div *ngIf="status == SwapStatus.failed || status == SwapStatus.rejected || status == SwapStatus.complete"
      class="err-div">
      <img src="./assets/images/error.svg" alt="" height="64" width="64" *ngIf="status != SwapStatus.complete">
      <img src="./assets/images/success.svg" alt="" height="64" width="64" *ngIf="status == SwapStatus.complete">
    </div>
    <div class="fw-light text-center p-3 mt-3" *ngIf="status == SwapStatus.not_started"> Please confirm the transaction
      in
      your wallet in order to proceed
    </div>

    <div class="fw-light text-center p-3 mt-3" *ngIf="status == SwapStatus.approval_pending"> Before you can lock your
      tokens to commence the bridge, we first need your approval to access your LBLOCK. Please confirm the token
      approval transaction in your wallet to continue.
    </div>

    <div class="fw-light text-center p-3 mt-3" *ngIf="status == SwapStatus.in_progess"> Please wait whilst we bridge
      your
      tokens from {{isBSCChain() ? 'BSC': 'Ethereum'}} to {{isBSCChain() ? 'Ethereum': 'BSC'}}. </div>

    <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.complete"> The token has been bridged
      successfully. Switch to {{isBSCChain() ? 'Ethereum': 'BSC'}} network
      now to claim your LBLOCK tokens.</div>

    <div class="text-center mt-2 mt-md-3" *ngIf="status == SwapStatus.complete">
      <button (click)="onSwitch()" class="btn btn-gradient-border" [disabled]="loader"><span>Switch
          Network</span></button>
      <button class="btn btn-gradient-border ml-3" [disabled]="loader" (click)="tsHash()"><span>View
          Transaction</span></button>
    </div>

    <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.failed"> Sorry! Your transaction to bridge
      tokens failed. Please make sure you
      use enough gas to cover the fees and try again. </div>

    <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.rejected"> The transaction to bridge your
      tokens was rejected. Please try closing this popup and try again.</div>

  </div>