<section class="homeWrap d-flex align-items-center justify-content-center">
  <div class="container py-4 py-md-5">
    <div class="lucky-bridege-text row align-items-center justify-content-between ">

      <div
        class="col-12 col-md-7 col-lg-5 col-sm-12 px-sm-3 px-lg-5 d-flex flex-column align-items-center justify-content-center mb-4 mb-md-0">
        <div *ngIf="walletAddress" class="lucky-bridge-card">
          <h5 class="lucky-block-bridge-title text-left">Lucky Block Bridge</h5>
          <p class="bsc-title">{{isBSCChain() ? 'BSC': 'ETH'}} <span>
              <>
            </span>{{!isBSCChain() ? 'BSC': 'ETH'}}</p>
          <div class="card text-center">
            <div class="card-body d-flex justify-content-between">
              <div class="card-title-icon d-flex m-2 w-100">
                <img src="../../../assets/images/bnb-icon.svg" alt="" height="32" width="32" *ngIf="isBSCChain()">
                <img src="../../../assets/images/ethereum.svg" height="32" alt="" *ngIf="!isBSCChain()">

                <p class="bscbalance mx-2 my-2">LuckyBlock {{isBSCChain() ? '(BEP-20)': '(ERC-20)'}}: {{tokenBalance}}
                  LBLOCK</p>

              </div>
            </div>
            <div class="card-body-data" *ngIf="isBSCChain()">
              <p class="available_lblock mt-2 mx-2">You will send <span class="text-white"> {{tokenBalance}} LBLOCK
                </span>to
                the {{!isBSCChain() ? 'BSC':'ETH'}} Network. <span *ngIf="false">You will be charged <span
                    class="text-white">{{tax}} LBLOCK</span> as a transaction tax.</span></p>


              <button class="btn btn-gradient-border text-black mb-4" (click)="openSwap()"
                [disabled]="tokenBalance <= 0 || (+tokenBalance) <= 0"><span> SEND
                  TOKENS</span></button>
            </div>
            <div class="card-body-data">
              <button class="btn btn-gradient-border text-black mb-4" (click)="onSwitch()"><span> SWITCH TO
                  {{isBSCChain() ? "ETH" : "BSC"}} CHAIN</span></button>
            </div>


          </div>

          <div class="card mt-3 p-3" *ngIf="isBSCChain()">
            <h2 class="text-white fw-bold text-center">Your Locks</h2>
            <h6 class="text-white fw-bold text-center">Below you can see the information about any LBLOCK tokens you've
              locked on the BSC Network. This lock information will be migrated to the Ethereum network so that your V2
              LBLOCK tokens can be claimed.</h6>
            <table class="text-white">
              <ng-container *ngFor="let slotData of bridgeSlotData">
                <tr class="d-flex align-items-center justify-content-center" *ngIf="slotData.vestedAmount > 0">
                  <td class="m-2 p-2">{{slotData.vestedAmount}} LBLOCK</td>
                </tr>
              </ng-container>
            </table>
            <div *ngIf="bridgeSlotData?.length == 0" class="text-white fw-bold text-center mt-3">No Transactions yet.
            </div>
          </div>

          <div class="card mt-3 p-3" *ngIf="!isBSCChain()">
            <h2 class="text-white fw-bold text-center">Bridged Tokens</h2>
            <table class="text-white">
              <tr>
                <th>Bridged Amount</th>
                <th>Claimed</th>
                <th>Claimable</th>
                <th>&nbsp;</th>
              </tr>
              <ng-container *ngFor="let claimData of claimsData">
                <tr class="my-3 py-3" *ngIf="claimData.totalAmount > 0">
                  <td class="m-2 p-2">{{claimData.totalAmount}} LBLOCK</td>
                  <td class="m-2 p-2">{{+claimData.claimedAmount.toFixed(2)}} LBLOCK</td>
                  <td class="m-2 p-2">{{+claimData.claimableAmount.toFixed(2)}} LBLOCK</td>
                  <td>
                    <button class="btn btn-gradient claim-btn" (click)="openClaim(claimData.id)"
                      *ngIf="+claimData.claimableAmount > 0">CLAIM</button>
                  </td>
                </tr>
              </ng-container>
            </table>
            <div *ngIf="claimsData?.length == 0" class="text-white fw-bold text-center mt-3">No Transactions yet.</div>
          </div>
        </div>

        <div *ngIf="!walletAddress">
          <div class="d-flex flex-column">
            <p class="metamask-text text-uppercase fw-bold fs-2 lh-base mb-4">A WORLDWIDE LOTTERY WITH PLAYERS USING
              BLOCKCHAIN PROTOCOLS.</p>
          </div>
          <p class="lucky-block-desc mt-4 d-inline-block ml-3">Before you get started you’ll need a wallet connected to
            BSC Mainnet. If you’re not sure how to do this, <a class="click-here" (click)="infoPopup()"> click
              here.</a>
          </p>
          <button *ngIf="!walletAddress" class="btn btn-gradient" (click)="openWalletDialog()">CONNECT WALLET</button>
        </div>
        <a href="javascript:void(0)" *ngIf="walletAddress"
          class="text-decoration-underline text-white fs-5 fw-lighter mt-4" (click)="disconnect()">Disconnect</a>

      </div>
      <div class="col-12 col-md-5 col-lg-6">
        <img src="./assets/images/graphic_1.png" alt="" *ngIf="walletAddress === ''" class="img-fluids sectionImage">
        <img src="./assets/images/graphic_2.png" alt="" *ngIf="walletAddress != ''" class="img-fluid sectionImage">
      </div>
      <div class="col-12 my-3" *ngIf="windowSize < 768">
        <ul class="footer-list m-0 p-0">
          <li><a href="https://twitter.com/luckyblockcoin?s=21" target="_blank"> <img
                src="assets/images/svg-icons/twitter.svg" alt="" class="cursor-pointer"></a></li>
          <li><a href="https://t.me/OfficialLuckyBlock" target="_blank"><img src="assets/images/svg-icons/telegram.svg"
                alt="" class="cursor-pointer"></a></li>

          <li><a href="https://discord.gg/U9HDC2Tsmk" target="_blank"><img src="assets/images/svg-icons/discord.svg"
                alt="" class="cursor-pointer"></a></li>
          <li>
            <a href="https://youtube.com/@LuckyBlockCoin" target="_blank" class="cursor-pointer">
              <img src="../../../assets/images/svg-icons/youtube.svg" alt=""></a>
          </li>
          <li><a href="https://m.facebook.com/LuckyBlockLB" target="_blank" class="cursor-pointer"> <img
                src="../../../assets/images/svg-icons/facebook.svg" alt=""></a></li>
          <li><a href="https://instagram.com/luckyblockcasino?igshid=OGIzYTJhMTRmYQ==" target="_blank"
              class="cursor-pointer"> <img src="../../../assets/images/svg-icons/instagram.svg" alt=""></a></li>
        </ul>
      </div>
    </div>
    <div class="troubleshooting-title d-flex justify-content-md-end justify-content-center">
      <p class="text-right" (click)="openTroubleshoot()">Troubleshooting Guide</p>
    </div>
  </div>
</section>

<ng-template #clickDialog>
  <div class="d-flex justify-content-between">
    <p class="troubleshooting text-white font-weight-bold"> Troubleshooting </p>
    <a (click)="closePopup()" class="closeBtn ms-auto" role="button">
      <mat-icon class="text-white">close</mat-icon>
    </a>
  </div>
  <div class="popUpBody px-2">
    <p class="trouble-title  text-white mb-4">Having trouble? See the troubleshooting guide below for some tips on
      common
      issues that you might be experiencing and how to fix them.</p>
    <h5 class=" text-white lh-base">Having issues on Mobile?</h5>
    <p class="trouble-title  text-white font-weight-medium">If you are going to use MetaMask on mobile then please be
      aware that the user experience can be a bit buggy which is unfortunately out of our control! We recommend using
      MetaMask on web. </p>
    <p class="trouble-title  text-white font-weight-medium">
      If you’re having issues on mobile, there can be a few reasons for this. </p>
    <ul>
      <li class="trouble-title text-white font-weight-medium">Please make sure that your mobile wallet supports, and is
        connected to,
        the Binance Smart Chain Mainnet. </li>
      <li class="trouble-title text-white font-weight-medium">Please ensure you’re opening any Wallet Connect links in
        the wallet,
        otherwise the deep linking may direct you to the app store instead.</li>

    </ul>
    <h5 class=" text-white lh-base">Having issues on Web?</h5>
    <p class="mb-4 trouble-title  text-white font-weight-medium">We recommend using Chrome, Firefox or Edge when using
      the Lucky Block Onramper. If you’re having issues, please ensure you’re using one of the supported browsers and
      ensure you don’t have the Coinbase Wallet extension installed. </p>


    <h5 class=" text-white lh-base">Can I use Brave browser?</h5>
    <p class="trouble-title  text-white font-weight-medium">If you want to use Brave browser, simply follow the
      instructions for MetaMask. As the Brave wallet takes priority over the MetaMask wallet, they may clash if you have
      both installed at the same time. If you want to use MetaMask on Brave, go to your Brave settings and set Default
      Cryptocurrency Wallet to “None”. </p>
    <h5 class=" text-white lh-base">When I use Wallet Connect it takes me to the App Store</h5>
    <p class="mb-4 trouble-title  text-white font-weight-medium">Wallet Connect has some known issues with deep linking
      - sometimes your phone will open the links in the correct wallet, and other times it will open the links in the
      App Store. </p>
    <p class="mb-4 trouble-title  text-white font-weight-medium">Unfortunately this is something outside of our control.
      On some devices you can long-press the wallet you want to connect, and choose to open the link directly in the
      app. This is a work around for some devices, but isn’t 100% consistent. If you’re having issues, try long-pressing
      to see if you can open the app directly. Where possible, we recommend using desktop to make purchases. </p>

    <h5 class=" text-white lh-base">Can’t connect with Metamask?</h5>
    <p class="trouble-title  text-white font-weight-medium">Ensure you don’t have the Coinbase Wallet extension
      installed - this can intefere with the Metamask wallet extension. If you have Coinbase Wallet installed, make
      sure you deactivate it before proceeding. </p>
  </div>
</ng-template>



<ng-template #bfcMainnet>
  <div class="d-flex justify-content-between">
    <p class="troubleshooting text-white font-weight-bold"> Adding BSC Mainnet </p>
    <a (click)="closePopup()" class="closeBtn ms-auto" role="button">
      <mat-icon class="text-white">close</mat-icon>
    </a>
  </div>
  <div class="popUpBody px-2">
    <p class="trouble-title  text-white mb-4">Trying to connect your wallet and having issues? Make sure you follow the
      instructions below to add BSC Mainnet to your wallet.</p>

    <h5 class="text-white lh-base">Step 1: Install MetaMask</h5>
    <p class="trouble-title  text-white font-weight-medium">First, you need to make sure you’ve got MetaMask installed.
      This can be done on most modern browsers, alongside iOS and Android. For the most seamless experience we’d
      recommend using the browser extension when installing MetaMask. If you are going to use metamask on mobile then
      please be aware that the user experience can be a bit buggy which is unfortunately out of our control!</p>
    <p class="trouble-title  text-white font-weight-medium">Firstly, head on over to the <a
        href="https://metamask.io/download/" target="_blank">MetaMask Download page</a>. From
      there, all you need to do is select the platform you’re using and follow the installation steps on your device.
    </p>

    <h5 class=" text-white lh-base">Step 2: Adding Binance Smart Chain</h5>
    <p class="trouble-title  text-white font-weight-medium">You need to make sure your wallet is connected to Binance
      Smart Chain, as by default it will probably be connected to the Ethereum network.
    </p>
    <p class="trouble-title  text-white font-weight-medium"> First, you need to access the settings and from there head
      to the networks menu. </p>
    <p class="trouble-title  text-white font-weight-medium">In the networks menu, click on Add Network in order to
      manually add Binance Smart Chain to your wallet. You then need to add the mainnet using the parameters below:</p>
    <p class="trouble-title  text-white font-weight-medium">
      Network Name: Smart Chain <br>
      New RPC URL: https://bsc-dataseed.binance.org/<br>
      ChainID: 56<br>
      Symbol: BNB<br>
      Block Explorer URL: https://bscscan.com</p>
    <p class="trouble-title  text-white font-weight-medium"> Once this is done, hit save and return to the main view.
      All you need to do now is make sure you’re connected to “Smart Chain” instead of “Ethereum” and you’re good to go!
    </p>

    <h5 class=" text-white lh-base">Step 3: Get LBLOCK</h5>
    <p class="trouble-title  text-white font-weight-medium">Once you’ve followed the steps above you’re all set, so
      close this popup, connect your wallet and follow the instructions to get your hands on some LBLOCK tokens. </p>


  </div>
</ng-template>

<!-- Wallet connect modal popup -->
<ng-template #walletDialog>
  <div class="fs-3 fw-bold mb-4 text-uppercase text-center">SELECT A PROVIDER</div>
  <div class="d-flex justify-content-around gap-3 text-center">
    <div class="cursor-pointer walletBtn" (click)="connectWallet('metamask')" role="button" *ngIf="windowSize >= 768">
      <img src="./assets/images/metamask.svg" alt="metamask" class="walletIcon mb-3">
      <span class="d-block fs-5 fw-bold text-uppercase">Metamask</span>
    </div>
    <div class="cursor-pointer walletBtn" (click)="connectWallet('wallet')" role="button">
      <img src="./assets/images/wallet_connect.svg" alt="wallet_connect" class="walletIcon mb-3">
      <span class="d-block fs-5 fw-bold text-uppercase">Wallet Connect</span>
    </div>
  </div>
</ng-template>