import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventService } from '../event.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private eventService: EventService
    ) {}

    // used to set loaders on api call
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.eventService.count = 0;
    if (this.eventService.checkList(request.url)) {
      this.eventService.broadcastEvent("LOADER", {display: true, req: request.url});
    }
    return next.handle(request)
    .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
      if (evt instanceof HttpResponse && this.eventService.checkList(request.url)) {
        this.eventService.broadcastEvent("LOADER", {display: false, req: null});
      }
      return evt;
    }));
  }

}
