import { Component } from '@angular/core';
import { EventService } from './services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  showLoader: boolean = false;
  constructor(
    private eventService: EventService
  ){}
// setting the global loader active/inactive
  ngAfterViewInit(): void {
    this.eventService.getEvent('LOADER').subscribe(val => {
      if(val.display && val.req && this.eventService.checkList(val.req)){
        this.showLoader = true;
      }else{
        this.showLoader = false;
      }
    });
  }

}
