import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllConnectionStatusService } from 'src/app/services/all-connection-status.service';
import { EventService } from 'src/app/services/event.service';
import { PopupService } from 'src/app/services/popup.service';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  isConnected: boolean = false;
  windowSize: number = 0;
  rampContent: any;
  isMetamask = true;


  @ViewChild('networkDialog') networkDialog!: TemplateRef<any>;
  @ViewChild('fiatDialog') fiatDialog!: TemplateRef<any>;
  @ViewChild('walletDialog') walletDialog!: TemplateRef<any>;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = event.target.innerWidth;
  }

  constructor(
    private walletConnectService: WalletConnectService,
    private dialog: MatDialog,
    private popupService: PopupService,
    private eventService: EventService
  ) {

    this.windowSize = window.screen.width;

    this.eventService.getEvent('invalidChain').subscribe(val => {
      this.isMetamask = val;
      this.openNetworkDialog();
    });

  }

  get walletAddress() {
    return this.walletConnectService.walletAddress;
  }

  ngOnInit(): void {

  }

  connectWallet(mode: string): void {
    this.walletConnectService.connectWallet(mode);
    this.closePopup();
  };

  // when switching the chains
  onSwitch() {
    let service = this.walletConnectService;
    if (this.isBSCChain()) {
      service.switchNetwork(environment.eth.chainIdInt);
    } else {
      service.switchNetwork(environment.bsc.chainIdInt);
    }
    this.closePopup();
  }

  closePopup(): void {
    this.dialog.closeAll();
  }

  isBSCChain() {
    let service = this.walletConnectService;

    return service.chainId == environment.bsc.chainIdInt;
  }

  openWalletDialog(): void {
    this.dialog.open(this.walletDialog, { width: '510px', maxWidth: '90vw', panelClass: 'alertDialog', disableClose: false }).afterClosed().subscribe(result => {
      if (result) {
        this.popupService.messsageAlert('warning', result);
      }
    });
  }
  openNetworkDialog(): void {
    this.dialog.open(this.networkDialog, { width: '510px', maxWidth: '90vw', panelClass: 'alertDialog', disableClose: false }).afterClosed().subscribe(result => {
      if (result) {
        this.popupService.messsageAlert('warning', result);
      }
    });
  }
  disconnect(): void {
      this.walletConnectService.disConnectWallet();
  }

  connectBSC() {
    let service = this.walletConnectService;
    service.switchNetwork(environment.bsc.chainIdInt);
    this.dialog.closeAll();
  }

  connectETH() {
    let service = this.walletConnectService;
    service.switchNetwork(environment.eth.chainIdInt);
    this.dialog.closeAll();
  }

}
