<div class="modalHeader mb-4 text-center">
    <div class="fs-1 fw-bold text-uppercase mb-2">Claim Tokens</div>
    <div class=" text-white">Please confirm the transaction in Wallet to claim your tokens.
    </div>
</div>
<div class="modalContent p-3 p-md-4 rounded">
    <div class="d-flex align-items-center justify-content-between mb-3 mb-md-4">
        <span class="fs-5 fw-bold me-2">Claim your LBLOCK</span>
        <a (click)="closePopup()" class="closeBtn ms-auto" role="button">
            <img src="./assets/images/close.svg" alt="" style="height: 70%; width: 70%;">
        </a>
    </div>
    <div class="text-center mt-1 mt-md-1 d-flex flex-column align-items-center">
        <img src="./assets/images/graphic_1.png" height="200" alt="">
        <img src="./assets/images/loader.svg" height="48" alt="progress" class=""
            *ngIf="status == SwapStatus.in_progess">
        <img src="./assets/images/check.svg" height="48" alt="complete" class="" *ngIf="status == SwapStatus.complete">
        <img src="./assets/images/error.svg" height="48" alt="failed" class=""
            *ngIf="status == SwapStatus.failed || status == SwapStatus.rejected">
        <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.not_started">Please confirm the
            transaction in your wallet in order to proceed.</div>
        <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.in_progess">Please wait whilst the
            tokens are transfered to your account. </div>
        <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.complete"> The token has been claimed
            successfully. You can view your LBLOCK tokens in your wallet. </div>
        <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.failed"> Sorry! Your transaction to
            claim tokens failed. Please make sure you use enough gas to cover the fees and try again. </div>
        <div class="text-white text-center  p-3 mt-3" *ngIf="status == SwapStatus.rejected"> The transaction to claim
            your tokens was rejected. Please try closing this popup and try again.</div>
        <div class="text-center mt-2 mt-md-3" *ngIf="status == SwapStatus.complete">
            <button class="btn btn-gradient-border ml-3" (click)="tsHash()"><span>View Transaction</span></button>
        </div>
    </div>
</div>