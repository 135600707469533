import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PopupService } from 'src/app/services/popup.service';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { SwapStatus } from 'src/app/shared/constants/app-enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})

export class SwapComponent implements OnInit {
  baseToken: any;
  SwapStatus = SwapStatus;
  loader = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public popupService: PopupService,
    public dialogRef: MatDialogRef<SwapComponent>,
    private walletConnectService: WalletConnectService
  ) {
    let service = this.walletConnectService;
    service.swapStatus = SwapStatus.not_started;
   }

  ngOnInit(): void {
    this.sendToken();
  }

  get service() {
    return this.walletConnectService;
  }

  get walletAddress() {
    return this.service.walletAddress;
  }

  get status() {
    return this.service.swapStatus;
  }

  get tax() {
      // return this.service.taxAmount
      return 0;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  closePopup(message = 'Transaction Cancelled'): void {
    this.dialogRef.close(message);
  }

  isBSCChain() {
    return this.service.chainId == environment.bsc.chainIdInt;
  }

  startAgain(): void {
    this.closePopup('startAgain');
  }

  done() {
    this.closePopup('Transaction Successful');
  }

  // switch network
  onSwitch() {
    if (this.isBSCChain()) {
      this.service.switchNetwork(environment.eth.chainIdInt);
    } else {
      this.service.switchNetwork(environment.bsc.chainIdInt);
    }
    this.closePopup();
  }

  // send token from one chain to another
  sendToken = async () => {
    this.loader = true;
    console.log("send");
    try {
      await this.service.bridgeToEth();
    }
    catch(err: any) {
      this.popupService.messsageAlert("error", "Transaction Failed");
      console.log(err);
      if (err && err.code == 4001)
      {
        this.service.swapStatus = SwapStatus.rejected;
      } else {
        this.service.swapStatus = SwapStatus.failed;
      }
    }
    this.loader = false
  }

  //get transaction hash
  tsHash() {
    let url: any;
    const append = "tx/" + this.walletConnectService.swapHash;
    if (this.isBSCChain()) {
      url = environment.bsc.chainInfo.params[0].blockExplorerUrls + append;
    }
    else {
      url = environment.eth.chainInfo.params[0].blockExplorerUrls + append;
    }
    window.open(url, '_target')
  }

  getStatus() {
    switch (this.status) {
      case SwapStatus.not_started:
        return "Not Started";
      case SwapStatus.in_progess:
        return "In Progress";
      case SwapStatus.complete:
        return "Successful";
      case SwapStatus.failed:
        return "Failed";
      case SwapStatus.rejected:
        return "Rejected";
      default:
        return "In Progress";
    }
  }

  getTaxText() {
    if (this.isBSCChain())
    {
      if (this.tax == 0)
        return "Tax estimation loading";
      return 'Estimated tax amount = ' + this.tax + ' LBLOCK';
    }
    return "";
  }

}


