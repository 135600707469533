import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClaimComponent } from '../claim/claim.component';
import { SwapComponent } from '../swap/swap.component';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { PopupService } from 'src/app/services/popup.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-metamask',
  templateUrl: './metamask.component.html',
  styleUrls: ['./metamask.component.scss']
})

export class MetamaskComponent implements OnInit {
  envirotnment: any = environment;
  windowSize: number = 0;
  rampContent: any;
  tokenUpdate = new Subject<any>();
  swapToken = 0;

  @ViewChild('clickDialog') clickDialog!: TemplateRef<any>;
  @ViewChild('bfcMainnet') bfcMainnet!: TemplateRef<any>;
  @ViewChild('fiatDialog') fiatDialog!: TemplateRef<any>;
  @ViewChild('walletDialog') walletDialog!: TemplateRef<any>;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = event.target.innerWidth;
  }
  constructor(
    private dialog: MatDialog,
    private walletConnectService: WalletConnectService,
    private popupService: PopupService
  ) {
    this.windowSize = window.screen.width;
  }

  ngOnInit(): void {
    setTimeout(async () => {
      // ====== checking for already connected or not ======
      this.walletConnectService.activateSite();
    }, 1000);
  }

  connectWallet(mode: string): void {
    this.walletConnectService.connectWallet(mode);
    this.closePopup();
  };

  disconnect(): void {
    this.walletConnectService.disConnectWallet();
}

  get service() {
    return this.walletConnectService
  }

  get walletAddress(): any {
    return this.service.walletAddress;
  }

  get tax() {
    // return this.service.taxAmount;
    return 0;
  }

  // open swap popup
  openSwap(): void {
    this.dialog.open(SwapComponent, { width: '540px', maxWidth: '90vw', backdropClass: 'fadedBackdrop', panelClass: 'transDialog', disableClose: true }).afterClosed().subscribe(result => {
      if (result && result === 'startAgain') {
        this.openSwap();
      }
    });
  }

  // open claim popup
  openClaim(slotId: number): void {
    this.dialog.open(ClaimComponent, { width: '540px', maxWidth: '90vw', backdropClass: 'fadedBackdrop', panelClass: 'transDialog', disableClose: true, data: { claim: this.claimableBalance, slotId } })
      .afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
      });
  }

  get claimsData() {
    return this.walletConnectService.chainData.vestingSlotData?.filter((slot: any )=> slot.totalAmount > 0);
  }

  openWalletDialog(): void {
    this.dialog.open(this.walletDialog, { width: '510px', maxWidth: '90vw', panelClass: 'alertDialog', disableClose: false }).afterClosed().subscribe(result => {
      if (result) {
        this.popupService.messsageAlert('warning', result);
      }
    });
  }

  get bridgeSlotData() {
    return this.walletConnectService.chainData.bridgeSlotData?.filter((slot: any )=> slot.vestedAmount > 0);
  }

  closePopup(): void {
    this.dialog.closeAll();
  }

  get claimableBalance() {
    return this.service.chainData.claimableTokens.toString();
  }

  get tokenBalance() {
    return Number(this.service.tokenBalance.toFixed(3));
  }

  isBSCChain() {
    return this.service.chainId == environment.bsc.chainIdInt;
  }

  backToLblock() {
    window.location.href = "https://luckyblock.com/";
  }

  openTroubleshoot(): void {
    this.dialog.open(this.clickDialog, { width: '716px', maxWidth: '90vw', panelClass: 'guide', disableClose: false }).afterClosed().subscribe(result => {
      if (result) {
        this.openTroubleshoot();
      }
    });
  }

  infoPopup(): void {
    this.dialog.open(this.bfcMainnet, { width: '716px', maxWidth: '90vw', panelClass: 'guide', disableClose: false }).afterClosed().subscribe(result => {
      if (result) {
        this.openTroubleshoot();
      }
    });
  }

  onSwitch() {
    let service = this.walletConnectService;
    if (this.isBSCChain()) {
      service.switchNetwork(environment.eth.chainIdInt);
    } else {
      service.switchNetwork(environment.bsc.chainIdInt);
    }
    this.closePopup();
  }

  openSupport() {
    window.open('https://guardarian.freshdesk.com/support/tickets/new', '_blank');
  }
}
